import React, { useEffect } from 'react';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import { DataProvider } from './context/DataContext';
import InitialLoad from './InitialLoad'; // Importieren Sie Ihre InitialLoad Komponente
import Navbar from './components/navbar';
import Header from './components/Header';
import ContentSection from './components/ContentSection';
import Team from './components/team';
import Personal from './components/personal';
import Impressum from './components/impressum';
import ContactUs from './components/contact';

import packageInfo from '../package.json';


function App() {
    useEffect(() => {
      document.title = "Sijelly IT GmbH" ; // Setze hier deinen gewünschten Titel
    }, []);

  return (
    <DataProvider>
      <Router>
        <div className="App">
          <Navbar />
          <Routes>
            <Route path="/" element={<InitialLoad />} /> {/* Verwenden Sie die InitialLoad Komponente auf der Startseite */}
            <Route path="/header" element={<><Header /><ContentSection /></>} />
            <Route path="/team" element={<Team />} />
            <Route path="/personal/:personalId" element={<Personal />} />
            <Route path="/impressum" element={<Impressum />} />
            <Route path="/contact" element={<ContactUs />} />
            </Routes>
          <Footer /> {/* Hinzufügen der Footer-Komponente */}
        </div>
      </Router>
    </DataProvider>
  );
}

// Footer-Komponente zum Anzeigen der Versionsnummer
function Footer() {
  return (
    <div style={{ position: 'fixed', right: '10px', bottom: '10px', fontSize: '12px' }}>
      v{packageInfo.version}
    </div>
  );
}

export default App;


